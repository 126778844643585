import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import rocketIcon from '../images/icons/rocket2.svg'
import securityIcon from '../images/icons/security2.svg'
import thumbIcon from '../images/icons/thumb.svg'
import contentIcon from '../images/icons/content2.svg'
import responsiveIcon from '../images/icons/responsive.svg'
import seoIcon from '../images/icons/seo.svg'
import aboutUsIllustration from '../images/about/illustration.svg'
import PageHero from "../components/PageHero/PageHero";
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";



function AboutPage() {
	const  data  = useStaticQuery(
		graphql`
		query {
		    missionImg: file(relativePath: { eq: "about/mission.jpg" }) {
		      ...fluidImage
		    }
		    visionImg: file(relativePath: { eq: "about/vision.jpg" }) {
		      ...fluidImage
		    }
		    growImg: file(relativePath: { eq: "about/grow.jpg" }) {
		      ...fluidImage
		    }
	    }
	    `
	)

	return (
		<Layout>
			<SEO
				title="About Us :: Fast Optimized Static Sites Development"
				description="We create fast, optimized, responsive, mobile first, static websites using Gatsby / React. Web Development Agency Tampa Florida"
				keywords={['static websites', 'gatsby website', 'react site', 'quick site', 'fast site', 'optimized webpage', 'responsive website', 'mobile firts sites', 'create site in Tampa', 'order website']}
			/>

			<PageHero title="About Us" />
			<div className="bg-gray-light py-20">
				<div className="container mx-auto">
					<div className="w-9/12 mx-auto text-center">
						<h2 className="text-2xl md:text-4xl leading-tight font-hero">Digital Agency for Your Business Solutions</h2>
						<p className="lead">We are a young team of experienced developers. And we offer you our years of experience in creating and promoting web projects.</p>
					</div>

					<div className="flex flex-wrap">
						<div className="w-full md:w-1/3 p-10 text-center">
							<Img fluid={data.missionImg.childImageSharp.fluid} alt=""/>
							<h5 className="mt-6 mb-2  leading-snug">Our Mission</h5>
							<p>Our mission is to give our customers the tools to help their business.
								<br/><strong>Because the success of our customers is our success!</strong></p>
						</div>
						<div className="w-full md:w-1/3 p-10 text-center">
							<Img fluid={data.visionImg.childImageSharp.fluid}  alt=""/>
							<h5 className="mt-6 mb-2 leading-snug">Our Vision</h5>
							<p>The main thing in websites to make the user satisfied.
								<br/><strong>That' s why we make websites quick and easy.</strong></p>
						</div>
						<div className="w-full md:w-1/3 p-10 text-center">
							<Img fluid={data.growImg.childImageSharp.fluid}  alt=""/>
							<h5 className="mt-6 mb-2 leading-snug">Why Us?</h5>
							<p>We believe that the client does not have to know the technologies.
								<br/> <strong>We are responsible for providing them with solutions.</strong></p>
						</div>
					</div>
				</div>
			</div>

			<div className="container mx-auto py-20 px-4 xl:px-0">
				<div className="flex flex-wrap">
					<div className="w-full md:w-1/2 pr-10">
						<h2 className="text-2xl lg:text-4xl leading-tight font-hero">We combine the speed of static sites with the power of content systems </h2>
						<p className="lead">The following reasons show the benefits of our fast websites</p>

						<ul className="mt-2">
							<li className="flex align-baseline mb-1">
								<img src={rocketIcon} className="w-5 mr-4"/> <p className="font-bold">Speedy Fast</p>
							</li>
							<li className="flex align-baseline mb-1">
								<img src={responsiveIcon} className="w-5 mr-4"/> <p className="font-bold">Responsive Design</p>
							</li>
							<li className="flex align-baseline mb-1">
								<img src={seoIcon} className="w-5 mr-4"/> <p className="font-bold">SEO-friendly</p>
							</li>
							<li className="flex align-baseline mb-1">
								<img src={securityIcon} className="w-5 mr-4"/> <p className="font-bold">Secure</p>
							</li>
							<li className="flex align-baseline mb-1">
								<img src={thumbIcon} className="w-5 mr-4"/> <p className="font-bold">Futureproofing your website</p>
							</li>
							<li className="flex align-baseline mb-1">
								<img src={contentIcon} className="w-5 mr-4"/> <p className="font-bold">Ease of editing content</p>
							</li>
						</ul>
					</div>
					<div className="w-full md:w-1/2 pl-10">
						<img src={aboutUsIllustration} className="w-full"/>
					</div>
				</div>
			</div>

			<div className="container mx-auto text-center">
				<Link to="/services" className="py-5 px-8 font-hero text-xl bg-orange-500 rounded text-white">See what we can do for you</Link>
			</div>
		</Layout>
	);
}

export default AboutPage;
