import React from 'react';
import {StyledBgImgSection} from "../Header/styles";

const PageHero = ({title}) => {
	return (
		<StyledBgImgSection className="background-img fixed-bg relative w-full block pt-16 lg:pt-24">
			<div className="container mx-auto flex py-6 lg:py-12 items-center justify-center">
				<div className="text-white z-10 text-center">
					<h1 className="text-white text-2xl md:text-3xl lg:text-6xl">{title}</h1>

				</div>
			</div>
		</StyledBgImgSection>
	);
};

export default PageHero;
